<template>
    <div v-if="payment">
        <div class="card" :class="{ 'border-danger': this.hasPaymentErros() }">
            <div class="card-header small-card-header d-flex align-items-center">
                <h6 class="fw-bold m-0">Payment Type & Amount</h6>
                <div class="ms-auto">
                    <button type="button" @click="loadRates" :disabled="disableGetRatesFees || rateInformationLoading" class="btn btn-sm btn-primary me-1 tiny-button">
                        <i class="fas fa-percentage"></i>
                        {{ rateInformationLoading ? 'Wait...' : 'Get Rates & Fees' }}
                    </button>
                    <button type="button" @click="clearAllPayment" class="btn btn-sm btn-outline-primary ms-1 tiny-button">Clear</button>
                </div>
            </div>
            <div class="card-body p-2">
                <form autocomplete="off">
                    <div class="row small-row justify-content-center mb-2">
                        <div class="col-8">
                            <label for="services" class="form-label m-0 tiny-font">Services</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select class="form-select form-select-sm" :class="{ 'border-danger': this.errors.services }" v-model="payment.paymentMethodId" @change="onChangeService" id="services">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in services" :value="item.id" :key="item.id">
                                    {{ item.value }}
                                </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <label for="currencyOfPayment" class="form-label m-0 tiny-font">Currency of payment</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select class="form-select form-select-sm" :class="{ 'border-danger': this.errors.currency }" v-model="payment.currencyId" @change="onChangeCurrency" id="currencyOfPayment">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in currencies" :value="item.id" :key="item.id">
                                    {{ item.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <loading :show="loading || localLoading" v-if="loading || localLoading"></loading>
                    <div v-else class="row justify-content-center d-flex mb-2 gy-2">
                        <div class="col-6">
                            <label class="form-label m-0 tiny-font">Exchange Rate:</label>
                            <div v-if="!this.$store.state.agent.permissions.includes('CHANGE_PUBLIC_RATES')">
                                {{ parseFloat(payment.exchangeRate).toFixed(2) }}
                            </div>
                            <div v-else>
                                <formatted-input v-model="payment.exchangeRate" :error="this.errors.exchangeRate" :inputValue="parseFloat(this.payment.exchangeRate).toFixed(2)" :disabled="loading" @input="debounce(updateExchangeRate)" @blur="updateExchangeRate"></formatted-input>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="form-label m-0 tiny-font">Total Fees:</label>
                            <div v-if="!this.$store.state.agent.permissions.includes('CHANGE_PUBLIC_RATES')">
                                {{ parseFloat(payment.totalFees).toFixed(2) }}
                            </div>
                            <div v-else>
                                <formatted-input v-model="payment.totalFees" :inputValue="parseFloat(this.payment.totalFees).toFixed(2)" :disabled="loading" @input="debounce(updateTotalFee)" @blur="updateTotalFee"></formatted-input>
                            </div>
                        </div>

                        <div class="col-6">
                            <label class="form-label m-0 tiny-font">Amount Sent (USD):</label>
                            <formatted-input v-model="payment.amountSent" :error="this.errors.amountSent" :inputValue="parseFloat(this.payment.amountSent).toFixed(2)" @input="debounce(onChangeAmountSentAndCost)" @blur="onChangeAmountSentAndCost"></formatted-input>
                        </div>
                        <div class="col-6">
                            <label v-if="currencyOfPaymentName" for="amountReceivedBRL" class="form-label m-0 tiny-font">Amount Received ({{ currencyOfPaymentName }})</label>
                            <label v-else for="amountReceivedBRL" class="form-label m-0 tiny-font">Amount Received</label>
                            <formatted-input v-model="payment.amountReceived" :error="this.errors.amountReceived" :inputValue="parseFloat(this.payment.amountReceived).toFixed(2)" @input="debounce(onChangeAmountReceived)" @blur="onChangeAmountReceived"></formatted-input>
                        </div>

                        <div class="col-12">
                            <div class="card-body bg-light rounded p-2" v-show="showDebitCardSurcharge">
                                <div class="row small-row justify-content-center">
                                    <label class="form-label m-0 tiny-font text-center">Credit Card Surcharge (0.20% fee): {{ parseFloat(debitCardSurcharge).toFixed(2) }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="showRateInformation">
                        <div class="d-flex justify-content-center">
                            <label class="border border-primary rounded p-2 fw-bold">Total collected (US$): {{ totalCollected }}</label>
                        </div>
                    </div>

                    <div v-show="fieldsVisibility.bankDepositInfo.display" class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-gray small-new-bank-account">Bank Account</span>
                            <div class="border-bottom my-2 mx-1 w-40"></div>
                            <button type="button" @click="newBankAccount" id="newBankAccount" class="btn btn-link p-0 fw-bold tiny-button"><i class="far fa-lg fa-address-book me-1"></i>New Bank Account</button>
                            <button type="button" @click="openBankAccounts" v-show="fieldsVisibility.bankDepositInfo.findBankDisplay" id="findBankAccount" class="btn btn-link p-0 fw-bold tiny-button"><i class="fas fa-address-book me-1"></i>Other Bank Accounts</button>
                            <button type="button" @click="openIbanAccounts" v-show="fieldsVisibility.bankDepositInfo.findIBANDisplay" id="findIBANDisplay" class="btn btn-link p-0 fw-bold tiny-button"><i class="fas fa-address-book me-1"></i>Find Iban Accounts</button>
                        </div>

                        <div class="row small-row">
                            <div class="col-5 mb-1" v-if="fieldsVisibility.bankDepositInfo.bankNameSelectSearchDisplay">
                                <label for="bankName" class="form-label m-0 tiny-font">Bank name</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <v-select class="small-select" :options="banks" inputId="bankName" id="bankName" label="value" :clearable="false" :reduce="(item) => item.id" v-model="payment.abaOrNo" @close="onChangeBankAndBranch"></v-select>
                            </div>
                            <div class="col-3 mb-1" v-show="fieldsVisibility.bankDepositInfo.bankAbaNumberLabelDisplay">
                                <label for="bankNumber" class="form-label m-0 tiny-font">Bank number</label>
                                <input type="text" v-model="payment.abaOrNo" class="form-control form-control-sm" id="bankNumber" placeholder="" :disabled="fieldsVisibility.bankDepositInfo.bankAbaNumberTextDisabled" />
                            </div>
                            <div class="col-4 mb-1" v-show="fieldsVisibility.bankDepositInfo.accountTypeSelectDisplay">
                                <label for="accountType" class="form-label m-0 tiny-font">Account type</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <select class="form-select form-select-sm" v-model="payment.accountType" id="accountType">
                                    <option value="" selected>- Select -</option>
                                    <option v-for="item in accountTypes" :value="item" :key="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row small-row">
                            <div class="col mb-1" v-show="fieldsVisibility.bankDepositInfo.branchNumberInputDisplay">
                                <label for="branchNumber" class="form-label m-0 tiny-font">Branch number</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <input id="branchNumber" type="text" v-model="payment.branchNo" :maxlength="fieldsVisibility.bankDepositInfo.branchIdMaxLength" :disabled="fieldsVisibility.bankDepositInfo.branchNumberInputDisable" @keydown="brazilBranchAndAccountNumberFieldMask" @focusout="onChangeBankAndBranch" class="form-control form-control-sm" />
                                <button type="button" v-show="payment.paymentMethodId == 31 && payment.abaOrNo && payment.branchNo" @click="showBranchLocation" id="showBankInformation" class="btn btn-link p-0 fw-bold tiny-button"><i class="far fa-lg fa-address-book me-1"></i>Branch address</button>
                            </div>
                            <div class="col mb-1" v-show="fieldsVisibility.bankDepositInfo.accountNumberInputDisplay">
                                <label for="accountNumber" class="form-label m-0 tiny-font">Account number</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <input type="text" v-model="payment.accountNumber" class="form-control form-control-sm" id="accountNumber" placeholder="" @keydown="brazilBranchAndAccountNumberFieldMask" :disabled="fieldsVisibility.bankDepositInfo.accountNumberInputDisabled" />
                            </div>
                        </div>
                        <div class="row small-row">
                            <div class="col mb-1" v-show="fieldsVisibility.bankDepositInfo.branchLocationDisplay">
                                <label for="branchLocation" class="form-label m-0 tiny-font">Branch location</label>
                                <input type="text" v-model="payment.branchLocation" class="form-control form-control-sm" id="branchLocation" :disabled="fieldsVisibility.bankDepositInfo.branchLocationDisable" />
                            </div>
                        </div>
                    </div>

                    <div v-show="fieldsVisibility.pixInfo.display" class="mt-2">
                        <div class="row small-row align-items-end">
                            <div class="col-md-4">
                                <label for="pixTypeId" class="form-label m-0 tiny-font">Pix type</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <select class="form-select form-select-sm" v-model="payment.pixTypeId" id="pixTypeId">
                                    <option value="" selected>- Select -</option>
                                    <option v-for="item in pixTypes" :value="item.id" :key="item.id">
                                        {{ item.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md">
                                <button type="button" id="btnOpenModalPixKeys" @click="openPixKeys" class="btn btn-primary text-uppercase tiny-button"><i class="fas fa-search"></i> Find Beneficiary Recent Pix</button>
                            </div>
                        </div>
                        <div class="">
                            <label for="branchLocation" class="form-label m-0 tiny-font">Pix ID</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input type="text" v-model="payment.pixId" class="form-control form-control-sm" id="pixId" />
                        </div>
                    </div>

                    <div v-show="fieldsVisibility.boletoInfo.display" class="mt-2">
                        <div class="row small-row">
                            <div class="col">
                                <label for="boletoNumber" class="form-label m-0 tiny-font">Boleto Number</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <input type="text" v-model="payment.boletoNumber" class="form-control form-control-sm" id="boletoNumber" />
                            </div>
                        </div>
                    </div>

                    <div v-show="fieldsVisibility.cashPickupInfo.display" class="my-2">
                        <label for="locations" class="form-label m-0 tiny-font">Payment Location</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                        <div class="row small-row">
                            <div class="col-md-6">
                                <select class="form-select form-select-sm" v-model="payment.payLocationId" @change="onChangeLocation" id="locations" v-show="fieldsVisibility.cashPickupInfo.paymentLocationSelectDisplay">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in locations" :value="item.id" :key="item.id">
                                        {{ item.value }}
                                    </option>
                                </select>
                                <div v-show="fieldsVisibility.cashPickupInfo.findLocationComponentDisplay" class="badge bg-light text-black form-control form-control-sm fw-normal text-start py-2 border-light me-3">
                                    <span>{{ payment.payLocationName }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <button type="button" @click="openCashPickupLocations" class="btn btn-primary text-uppercase tiny-button" id="btnFindLocation" v-show="fieldsVisibility.cashPickupInfo.findLocationComponentDisplay"><i class="fas fa-search"></i> Find Location</button>
                            </div>
                        </div>
                    </div>

                    <div v-show="fieldsVisibility.recipientInfo.display" class="mt-3">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-gray small-recipient-docs">Recipient documents</span>
                            <div class="border-bottom my-1 mx-1 w-100"></div>
                        </div>
                        <div class="row small-row d-flex align-items-center">
                            <div class="col-md-3" v-show="fieldsVisibility.recipientInfo.documentNumberDisplay">
                                <label for="documentNumberRecipient" class="form-label m-0 tiny-font">Document number</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <input type="text" v-model="payment.recipientDocumentNumber" class="form-control form-control-sm" id="documentNumberRecipient" />
                            </div>
                            <div class="col-md-3" v-show="fieldsVisibility.recipientInfo.documentTypeDisplay">
                                <label for="documentTypeRecipient" class="form-label m-0 tiny-font">Type</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <select class="form-select form-select-sm" v-model="payment.recipientDocumentType" id="documentTypeRecipient">
                                    <option value="" selected>- Select -</option>
                                    <option v-for="item in recipientDocumentTypes" :value="item.id" :key="item.id">
                                        {{ item.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-3 px-1" v-show="fieldsVisibility.recipientInfo.documentExpirationDisplay">
                                <label for="documentExpirationRecipient" class="form-label m-0 tiny-font">Expiration</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <div class="input-group p-1">
                                    <span class="input-group-text text-black-50 tiny-font"><i class="fas fa-calendar-day"></i></span>
                                    <input type="text" v-model="payment.recipientDocumentExpiration" class="form-control form-control-sm datePicker" id="documentExpirationRecipient" placeholder="mm/dd/yyyy" />
                                </div>
                            </div>
                            <div class="col-md-3 px-1" v-show="fieldsVisibility.recipientInfo.birthday">
                                <label for="documentExpirationRecipient" class="form-label m-0 tiny-font">Birthday</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <div class="input-group p-1">
                                    <span class="input-group-text text-black-50 tiny-font"><i class="fas fa-calendar-day"></i></span>
                                    <input type="text" v-model="payment.recipientBirthday" class="form-control form-control-sm datePicker" id="recipientBirthday" placeholder="mm/dd/yyyy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <modal-pix-keys ref="modalPixKeys" @selected="selectedPix" :recipient-id="parseInt(order.recipient.clientId)"></modal-pix-keys>
        <modal-bank-accounts ref="modalBankAccounts" @selected="selectedBank" :recipient-id="parseInt(order.recipient.clientId)"></modal-bank-accounts>
        <modal-cash-pickup-locations ref="modalCashPickupLocations" @selected="selectedCashPickupLocation" :pay-method-id="parseInt(payment.paymentMethodId)" :currency-option-id="parseInt(payment.currencyId)"></modal-cash-pickup-locations>
        <modal-iban-accounts ref="modalIbanAccounts" @selected="selectedIban" :recipient-id="parseInt(order.recipient.clientId)" :pay-method-id="parseInt(payment.paymentMethodId)"></modal-iban-accounts>
    </div>
</template>

<script>
import axios from 'axios';
import ModalPixKeys from '@/views/order/modals/PixKeys';
import ModalBankAccounts from '@/views/order/modals/BankAccounts';
import ModalIbanAccounts from '@/views/order/modals/IbanAccounts';
import ModalCashPickupLocations from '@/views/order/modals/CashPickupLocations';
import Loading from '@/components/Loading';
import { Datepicker } from 'vanillajs-datepicker';
import FormattedInput from '@/components/CurrencyInput';
import { allValuesArePositive } from '../../../helpers/calculateValues';

export default {
    name: 'NewOrderPayment',
    components: {
        ModalPixKeys,
        ModalBankAccounts,
        ModalIbanAccounts,
        ModalCashPickupLocations,
        Loading,
        FormattedInput,
    },
    data() {
        return {
            localLoading: false,
            formattedNumber: '',
            errors: this.$store.state.simpleOrder.errors.payment,
            newData: { payment: { exchangeRate: null } },
            validating: false,
            rateInformationLoading: false,
            fieldsVisibility: {
                bankDepositInfo: {
                    display: false,
                    accountNumberInputDisabled: false,
                    accountNumberInputDisplay: false,
                    accountTypeSelectDisplay: false,
                    bankAbaNumberLabelDisplay: false,
                    bankAbaNumberTextDisabled: false,
                    bankNameLabelDisplay: false,
                    bankNameLabelValue: null,
                    bankNameSelectSearchDisplay: false,
                    bankNameTextDisabled: false,
                    branchLocationDisable: false,
                    branchLocationDisplay: false,
                    branchNameLabelDisplay: false,
                    branchNumberInputDisable: false,
                    branchNumberInputDisplay: false,
                    branchNumberTextDisplay: false,
                    findBankDisplay: false,
                    findRecipientAccountDisplay: false,
                    paymentLocationSelectDisplay: false,
                    findIBANDisplay: false,
                },
                pixInfo: {
                    display: false,
                },
                boletoInfo: {
                    display: false,
                },
                cashPickupInfo: {
                    display: false,
                    findLocationComponentDisplay: false,
                    paymentLocationSelectDisplay: false,
                },
                recipientInfo: {
                    display: false,
                    documentExpirationDisplay: false,
                    documentNumberDisplay: false,
                    documentTypeDisplay: false,
                },
            },
            banks: [],
            services: [],
            locations: [],
            currencies: [],
            recipientDocumentTypes: [],
            documentExpirationRecipientDatePicker: null,
            recipientBirthdayDatePicker: null,
            timer: 0,
            defaultDebounceDelay: 2000,
            brazilCountryId: 42,
        };
    },
    mounted() {
        this.loadServices();
        this.loadBanks();
        this.loadPaymentObjects();

        const elemExpirationDate = document.getElementById('documentExpirationRecipient');
        this.documentExpirationRecipientDatePicker = new Datepicker(elemExpirationDate);
        this.onChangeDocumentExpiration(elemExpirationDate);
        if (this.payment.recipientDocumentExpiration) this.documentExpirationRecipientDatePicker.setDate(this.payment.recipientDocumentExpiration);

        const elemBirthdayDate = document.getElementById('recipientBirthday');
        this.recipientBirthdayDatePicker = new Datepicker(elemBirthdayDate);
        this.onChangeBirthday(elemBirthdayDate);
        if (this.payment.recipientBirthday) this.recipientBirthdayDatePicker.setDate(this.payment.recipientBirthday);
    },
    methods: {
        debounce(func) {
            const wait = this.defaultDebounceDelay;
            clearTimeout(this.timer);
            this.timer = setTimeout(func, wait);
        },
        clearErrors() {
            for (const key in this.errors) {
                if (Object.prototype.hasOwnProperty.call(this.errors, key)) {
                    this.errors[key] = false;
                }
            }
        },
        checkBankAccounts() {
            if (this.payment.paymentMethodId == 31 && this.order.recipient.bankAccounts.length === 1) {
                this.selectedBank(this.order.recipient.bankAccounts[0]);
            }
        },
        loadPaymentObjects() {
            if (this.order.paymentInformation !== null) {
                this.payment = {
                    paymentMethodId: this.order.paymentInformation.paymentMethodId,
                    paymentMethod: this.order.paymentInformation.paymentMethod,
                    paymentMethodName: this.order.paymentInformation.paymentMethodName,
                    currencyId: this.order.paymentInformation.currencyId,
                    currencyDescription: this.order.paymentInformation.currencyDescription,
                    payLocationId: this.order.paymentInformation.payLocationId,
                    payLocationName: this.order.paymentInformation.payLocationName,
                    amountSent: this.order.paymentInformation.amountSent,
                    amountReceived: this.order.paymentInformation.amountReceived,
                    boletoNumber: this.order.paymentInformation.boletoNumber,
                    pixTypeId: this.order.paymentInformation.pixTypeId,
                    pixId: this.order.paymentInformation.pixId,
                    debitCardSurcharge: this.order.paymentInformation.debitCardSurcharge,
                    recipientDocumentNumber: this.order.paymentInformation.recipientDocumentNumber,
                    recipientDocumentType: this.order.paymentInformation.recipientDocumentType,
                    recipientDocumentExpiration: this.order.paymentInformation.recipientDocumentExpiration,
                    recipientBirthday: this.order.paymentInformation.recipientBirthday,
                };

                this.rateInformation = {
                    exchangeRate: this.order.paymentInformation.exchangeRate,
                    totalFees: this.order.paymentInformation.totalFees,
                    currencySymbol: this.order.paymentInformation.currencySymbol,
                };

                this.bankInformation = {
                    abaOrNo: this.order.paymentInformation.abaOrNo,
                    bankName: this.order.paymentInformation.bankName,
                    branchNo: this.order.paymentInformation.branchNo,
                    accountNumber: this.order.paymentInformation.accountNumber,
                    branchLocation: this.order.paymentInformation.branchLocation,
                    accountType: this.order.paymentInformation.accountType,
                };

                if (this.payment.paymentMethodId !== 0) this.loadCurrencies();

                if (this.payment.currencyId !== 0) {
                    this.loadLocations();
                    this.loadBanks();
                }

                if (this.payment.amountSent > 0) {
                    this.loadRates();
                }
            }
        },
        loadServices() {
            axios
                .get('/niloagent/createordersimple/payment/services', {
                    params: {
                        agentId: this.order.recipient.clientId,
                        countryId: this.order.recipient.countryId,
                    },
                })
                .then((response) => {
                    this.services = response.data;
                })
                .catch((error) => {
                    this.services = null;
                    this.$toast.error(`Error to find the payment services: ${error.response.data.Message}`);
                });
        },
        loadCurrencies() {
            return axios
                .get('/niloagent/createordersimple/payment/currencies', {
                    params: {
                        countryId: this.order.recipient.countryId,
                        typeOfPaymentId: parseInt(this.payment.paymentMethodId),
                    },
                })
                .then((response) => {
                    this.currencies = response.data.currenciesAvailable;
                })
                .catch((error) => {
                    this.currencies = null;
                    this.$toast.error(`Error to find the currencies: ${error.response.data.Message}`);
                });
        },
        loadLocations() {
            if (this.fieldsVisibility.cashPickupInfo.paymentLocationSelectDisplay) {
                axios
                    .get('/niloagent/createorder/payment/select/locations', {
                        params: {
                            countryId: this.order.recipient.countryId,
                            payMethodId: this.payment.paymentMethodId,
                            currencyOptionId: this.payment.currencyId,
                        },
                    })
                    .then((response) => {
                        this.locations = response.data;
                    })
                    .catch((error) => {
                        this.locations = null;
                        this.$toast.error(`Error to find the locations ${error.response.data.Message}`);
                    });
            } else {
                this.locations = null;
            }
        },
        loadBanks() {
            axios
                .get('/niloagent/createorder/filter/payment/loadbanklist', {
                    params: {
                        countryId: this.order.recipient.countryId,
                        payMethodId: this.payment.paymentMethodId,
                        currencyOptionId: this.payment.currencyId,
                    },
                })
                .then((response) => {
                    this.banks = response.data;
                })
                .catch((error) => {
                    this.banks = null;
                    this.$toast.error(`Error to find the brazilian banks: ${error.response.data.Message}`);
                });
        },
        loadCurrencyRates() {
            this.localLoading = true;
            return axios
                .get('/niloagent/createordersimple/payment/rateandfee', {
                    params: {
                        SenderCountryId: this.order.sender.countryId,
                        RecipientCountryId: this.order.recipient.countryId,
                        NetAmountSent: 1,
                        AmountReceived: 0,
                        CurrencySentId: 1,
                        CurrencyOfPayment: this.payment.currencyId,
                        TypeOfPaymentID: this.payment.paymentMethodId,
                    },
                })
                .then((response) => {
                    this.errors.exchangeRate = false;
                    this.payment.exchangeRate = response.data.exchangeRate;
                    this.newData.payment.exchangeRate = response.data.exchangeRate;
                })
                .catch(() => {
                    this.errors.services = true;
                })
                .finally(() => {
                    this.localLoading = false;
                });
        },
        loadRates() {
            this.rateInformationLoading = true;
            return axios
                .get('/niloagent/createordersimple/payment/rateandfee', {
                    params: {
                        SenderCountryId: this.order.sender.countryId,
                        RecipientCountryId: this.order.recipient.countryId,
                        NetAmountSent: this.payment.amountSent,
                        AmountReceived: this.payment.amountReceived ?? 0,
                        CurrencySentId: 1,
                        CurrencyOfPayment: this.payment.currencyId,
                        TypeOfPaymentID: this.payment.paymentMethodId,
                    },
                })
                .then((response) => {
                    const data = response.data;
                    if (this.hasRates) {
                        this.payment.totalFees = data.totalFees;
                    } else {
                        this.payment.exchangeRate = data.exchangeRate;
                        this.payment.totalFees = data.totalFees;
                        this.payment.currencySymbol = data.currencySymbol;
                        this.payment.maxDiscountAllowed = data.maxDiscountAllowed;
                        this.payment.amountReceived = data.amountPaid;
                        this.payment.amountSent = data.amountSent;
                    }
                    this.$store.state.simpleOrder.paymentInformation.flatFee = data.flatFee;
                    this.$store.state.simpleOrder.paymentInformation.percentFee = data.percentFee;
                    this.$store.state.simpleOrder.paymentInformation.otherFees = data.otherFees;
                    this.$store.state.simpleOrder.paymentInformation.currencyDescription = data.currencyDescription;
                    this.$store.state.simpleOrder.paymentInformation.currencyIsoCode = data.currencyIsoCode;
                    this.payment.amountSentIsChanging = false;

                    return true;
                })
                .finally(() => {
                    this.rateInformationLoading = false;
                });
        },
        loadBranchLocation() {
            axios
                .get('/niloagent/createorder/filter/payment/branch', {
                    params: {
                        payMethodId: this.payment.paymentMethodId,
                        bankId: this.payment.abaOrNo,
                        branchId: this.payment.branchNo,
                    },
                })
                .then((response) => {
                    if (response.data) this.payment.branchLocation = response.data.branchName.trim();
                })
                .catch((error) => {
                    this.branchLocation = '';
                    this.$toast.error(`Error to find the branches: ${error.response.data.Message}`);
                });
        },
        showBranchLocation() {
            axios
                .get('/niloagent/createorder/filter/payment/branch', {
                    params: {
                        payMethodId: this.payment.paymentMethodId,
                        bankId: this.payment.abaOrNo,
                        branchId: this.payment.branchNo,
                    },
                })
                .then((response) => {
                    if (response.data) alert(response.data.branchName.trim() + ', ' + response.data.cityName.trim() + ', ' + response.data.cityLocation.trim());
                    else this.$toast.error(`Unfortunately we could not find more information about this branch.`);
                })
                .catch(() => {
                    this.$toast.error(`Unfortunately we could not find more information about this branch.`);
                });
        },
        loadFieldsVisibility() {
            axios
                .get('/niloagent/createordersimple/payment/fieldsvisibility', {
                    params: {
                        payMethodId: this.payment.paymentMethodId,
                        countryId: this.order.recipient.countryId,
                        currencyId: this.payment.currencyId,
                    },
                })
                .then((response) => {
                    this.fieldsVisibility = response.data;
                    this.loadLocations();
                    this.loadBanks();
                })
                .catch((error) => {
                    this.$toast.error(`Error to find the currencies: ${error.response.data.Message}`);
                });
        },
        loadRecipientDocumentTypes() {
            this.loading = true;
            axios
                .get('/niloagent/createorder/filter/recipientdocuments/typedoc', {
                    params: {
                        payMethodId: this.payment.paymentMethodId,
                        countryId: this.order.recipient.countryId,
                        currencyId: this.payment.currencyId,
                    },
                })
                .then((response) => {
                    this.recipientDocumentTypes = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Error to get recipient documents types: ${error.response.data.Message}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        calculateAmountReceived() {
            var usAmountSent = parseFloat(this.payment.amountSent);
            var usExchangeRate = parseFloat(this.payment.exchangeRate);

            this.payment.amountReceived = usAmountSent > 0 && this.showRateInformation ? Number(parseFloat(usAmountSent * usExchangeRate).toFixed(2)) : 0;
        },
        calculateAmountSent() {
            var usAmountReceived = parseFloat(this.payment.amountReceived);
            var usExchangeRate = parseFloat(this.payment.exchangeRate);

            this.payment.amountSent = usAmountReceived > 0 && this.showRateInformation && usExchangeRate != 0 ? Number(parseFloat(usAmountReceived / usExchangeRate).toFixed(2)) : 0;
        },
        loadRatesAfterSelectServices() {
            if (this.payment.currencyId > 0) {
                this.loadCurrencyRates();
            }
            if (this.payment.amountReceived == 0 && this.payment.amountSent == 0) {
                return;
            }
            this.loadRates().then(this.calculateAmountSent);
        },
        onChangeService() {
            this.errors.services = this?.payment?.paymentMethodId == 0;
            this.errors.currency = this?.payment?.paymentMethodId == 0;
            this.payment.paymentMethod = this.payment.paymentMethodId > 0 ? this.services.find((item) => item.id === this.payment.paymentMethodId).type : '';
            this.payment.paymentMethodName = this.payment.paymentMethodId > 0 ? this.services.find((item) => item.id === this.payment.paymentMethodId).value : '';
            this.clearPayments();
            this.loadCurrencies().then(this.loadRatesAfterSelectServices);
            this.checkBankAccounts();
        },
        onChangeCurrency() {
            this.errors.currency = this.payment.currencyId == 0;
            this.errors.exchangeRate = this.errors.currency && this.payment.exchangeRate == 0;
            this.loadRatesAfterSelectServices();
            this.loadLocations();
            this.loadBanks();
        },
        onChangeLocation() {
            this.payment.payLocationName = this.locations.find((item) => item.id === this.payment.payLocationId).value;
        },
        onChangeAmountSentAndCost() {
            this.errors.amountSent = this.payment.amountSent <= 0;
            this.errors.currency = this.payment.currencyId == 0;
            this.errors.services = this.payment.paymentMethodId == 0;
            this.errors.exchangeRate = this.payment.exchangeRate == 0;
            this.calculateAmountReceived();
            if (this.payment.amountSent > 0) {
                this.loadRates().then(this.validateExchangeRate);
            } else {
                this.validateExchangeRate();
            }
        },
        onChangeAmountReceived() {
            this.errors.amountReceived = this.payment.amountReceived <= 0;
            this.errors.exchangeRate = this.payment.exchangeRate == 0;

            if (this.payment.currencyId == 0 || this.payment.paymentMethodId == 0) {
                this.errors.currency = this.payment.currencyId == 0;
                this.errors.services = this.payment.paymentMethodId == 0;
                return;
            }
            this.calculateAmountSent();

            this.loadRates().then(this.validateExchangeRate);
        },
        onChangeBankAndBranch() {
            if (this.abaOrNo && this.abaOrNo.length > 0 && this.branchNo && this.branchNo.length > 0) this.loadBranchLocation();

            if (this.abaOrNo && this.abaOrNo.length > 0 && this.banks.length > 0)
                this.bankName = this.banks.find((item) => {
                    return item.id === this.payment.abaOrNo;
                }).value;
        },
        openBankAccounts() {
            this.$refs.modalBankAccounts.show();
        },
        openIbanAccounts() {
            this.$refs.modalIbanAccounts.show();
        },
        openPixKeys() {
            this.$refs.modalPixKeys.show();
        },
        openUpdateRateFees() {
            this.$refs.modalUpdateRateFees.show();
        },
        openCashPickupLocations() {
            this.$refs.modalCashPickupLocations.show();
        },
        selectedPix(pixInformation) {
            this.payment.pixTypeId = pixInformation.pixTypeId.toUpperCase();
            this.payment.pixId = pixInformation.pixId;
        },
        selectedBank(bankInformation) {
            this.$store.state.simpleOrder.paymentInformation = {
                ...this.payment,
                ...bankInformation,
            };
        },
        selectedCashPickupLocation(cashPickupLocation) {
            this.payment.payLocationId = cashPickupLocation.id;
            this.payment.payLocationName = cashPickupLocation.name;
        },
        clearPayments() {
            this.payment.payLocationId = 0;
            this.payment.payLocationName = '';
            this.payment.boletoNumber = '';
            this.payment.pixTypeId = '';
            this.payment.pixId = '';
            this.payment.abaOrNo = '';
            this.payment.branchNo = null;
            this.payment.bankName = null;
            this.payment.accountNumber = '';
            this.payment.accountType = '';
        },
        clearAllPayment() {
            this.clearErrors();
            this.clearPayments();
            this.payment.paymentMethodId = 0;
            this.payment.currencyId = 0;
            this.payment.amountSent = 0;
            this.payment.exchangeRate = 0;
            this.$store.commit('simpleOrder/newPayment');
            this.$store.commit('simpleOrder/setAdditionalInformation');
            this.aggregation30DaysFormated = '';
        },
        updateExchangeRate() {
            this.errors.exchangeRate = this.payment.exchangeRate <= 0;
            this.errors.currency = this.payment.currencyId == 0;
            this.errors.services = this.payment.paymentMethodId == 0;
            this.calculateAmountReceived();
            this.loadRates().then(this.validateExchangeRate);
        },
        validateExchangeRate() {
            if (allValuesArePositive(this.payment.exchangeRate, this.payment.amountReceived, this.payment.amountSent) && this.payment.exchangeRate != this.newData.payment.exchangeRate) {
                this.loading = true;
                axios
                    .post('/niloagent/createorder/filter/validateratesandcosts', {
                        requestInfo: {
                            senderCountryId: this.order.sender.countryId,
                            recipientCountryId: this.order.recipient.countryOfPaymentId,
                            netAmountSent: this.payment.amountSent,
                            currencySentId: 1,
                            currencyOfPayment: this.payment.currencyId,
                            typeOfPaymentID: this.payment.paymentMethodId,
                        },
                        exchangeRate: parseFloat(this.payment.exchangeRate),
                        totalFee: parseFloat(this.payment.totalFees),
                    })
                    .then(() => {
                        this.newData.payment.exchangeRate = this.payment.exchangeRate;
                        this.errors.exchangeRate = false;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.errors.exchangeRate = true;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        updateTotalFee() {
            if (allValuesArePositive(this.payment.exchangeRate, this.payment.AmountSent)) {
                this.loading = true;
                axios
                    .post('/niloagent/createorder/filter/validateratesandcosts', {
                        requestInfo: {
                            senderCountryId: this.order.sender.countryId,
                            recipientCountryId: this.order.recipient.countryOfPaymentId,
                            netAmountSent: this.payment.amountSent,
                            currencySentId: 1,
                            currencyOfPayment: this.payment.currencyId,
                            typeOfPaymentID: this.payment.paymentMethodId,
                        },
                        exchangeRate: parseFloat(this.payment.exchangeRate),
                        totalFee: parseFloat(this.payment.totalFees),
                    })
                    .then(() => {
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$toast.error(error.response.data.Message);
                    });
            }
        },
        onChangeDocumentExpiration(elem) {
            elem.addEventListener('changeDate', () => {
                this.payment.recipientDocumentExpiration = this.documentExpirationRecipientDatePicker.getDate('mm/dd/yyyy');
            });
        },
        onChangeBirthday(elem) {
            elem.addEventListener('changeDate', () => {
                this.payment.recipientBirthday = this.recipientBirthdayDatePicker.getDate('mm/dd/yyyy');
            });
        },
        newBankAccount() {
            this.payment.abaOrNo = '';
            this.payment.branchNo = null;
            this.payment.bankName = null;
            this.payment.accountNumber = '';
            this.payment.accountType = '';
        },
        selectedIban(ibanInformation) {
            this.$store.state.simpleOrder.paymentInformation = {
                ...this.payment,
                accountNumber: ibanInformation,
            };
        },
        brazilBranchAndAccountNumberFieldMask(event) {
            if (this.recipientCountryId != this.brazilCountryId) {
                return;
            }

            const key = event.key;

            if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
                return;
            }

            const allowedCommandKeys = ['c', 'v', 'a', 'x'];
            const isCtrlOrCmd = event.ctrlKey || event.metaKey;
            if (isCtrlOrCmd && allowedCommandKeys.includes(key.toLowerCase())) {
                return;
            }

            if (!/[0-9a-zA-Z]/.test(key)) {
                event.preventDefault();
                return;
            }
        },
        hasPaymentErros() {
            return this?.errors?.exchangeRate || this?.errors?.totalFees || this?.errors?.amountSent || this?.errors?.amountReceived || this?.errors?.services || this?.errors?.currency;
        },
        setExchangeRate() {
            this.loadCurrencies().then(this.loadRatesAfterSelectServices);
        },
    },
    computed: {
        payment() {
            return this.$store.state.simpleOrder.paymentInformation;
        },
        order() {
            return this.$store.state.simpleOrder;
        },
        information() {
            return this.$store.state.simpleOrder.additionalInformation;
        },
        currencyOfPaymentName() {
            return this.payment.currencyId > 0 && this.currencies.length > 0 ? this.currencies.find((item) => item.id === this.payment.currencyId).value : '';
        },
        showRateInformation() {
            return this.hasRates;
        },
        totalCollected() {
            if (this.payment.amountSent > 0) {
                let value = parseFloat(this.payment.amountSent) + parseFloat(this.payment.totalFees) + (this.payment.debitCardSurcharge || 0);
                return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
            }
            return '0.00';
        },
        debitCardSurcharge() {
            let value = this.payment.debitCardSurcharge;
            if (this.information.payMethod === 'DEBIT_CARD') {
                value = this.payment.amountSent * 0.002;
            }
            return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
        },
        disableGetRatesFees() {
            return !(this.payment.paymentMethod !== '' && (this.payment.currencyId !== 0 || this.payment.currencyId !== '') && (this.payment.amountSent > 0 || this.payment.amountReceived > 0));
        },
        hasRates() {
            return this.payment.exchangeRate > 0 || parseFloat(this.payment.totalFees) > 0;
        },
        showDebitCardSurcharge() {
            return this.payment.debitCardSurcharge != null && this.payment.debitCardSurcharge > 0;
        },
        serviceAndCurrency() {
            return this.payment.paymentMethodId + this.payment.currencyId;
        },
        recipientDocumentTypesDisplay() {
            return this.fieldsVisibility.recipientInfo.documentTypeDisplay;
        },
        hasSummary() {
            return this.$store.getters['order/hasSummary'];
        },
        paymentLocationSelectDisplay() {
            return this.fieldsVisibility.cashPickupInfo.paymentLocationSelectDisplay;
        },
        bankListSelectDisplay() {
            return this.fieldsVisibility.cashPickupInfo.bankNameSelectSearchDisplay;
        },
        amountToReceived() {
            return this.payment.amountSent > 0 && this.showRateInformation ? Number(parseFloat(parseFloat(this.payment.amountSent) * this.payment.exchangeRate).toFixed(2)) : 0;
        },
        recipientCountryId() {
            return this.$store.state.simpleOrder.recipient.countryId;
        },
        pixTypes() {
            return [
                {
                    id: 'CPF',
                    value: 'CPF or CNPJ',
                },
                {
                    id: 'PHONE',
                    value: 'Phone number',
                },
                {
                    id: 'EMAIL',
                    value: 'Email',
                },
                {
                    id: 'RANDOMKEY',
                    value: 'Random Key',
                },
            ];
        },
        accountTypes() {
            return ['CHECKING', 'SAVINGS'];
        },
        loading: {
            get: function () {
                return this.$store.state.simpleOrder.loading;
            },
            set: function (modifiedValue) {
                this.$store.state.simpleOrder.loading = modifiedValue;
            },
        },
    },
    watch: {
        'payment.amountReceived'() {
            if (Number(this.payment.amountReceived) > 0) {
                this.errors.amountReceived = false;
            }
        },
        'payment.exchangeRate'() {
            this.calculateAmountReceived();
        },
        serviceAndCurrency() {
            if (this.payment.paymentMethodId > 0 && this.payment.currencyId > 0) {
                this.calculateAmountReceived();
                this.loadFieldsVisibility();
            } else {
                this.fieldsVisibility.bankDepositInfo.display = false;
                this.fieldsVisibility.boletoInfo.display = false;
                this.fieldsVisibility.cashPickupInfo.display = false;
                this.fieldsVisibility.pixInfo.display = false;
                this.fieldsVisibility.recipientInfo.display = false;
            }
        },
        currencies(value) {
            if (value.length === 1) {
                this.payment.currencyId = value[0].id;
            }
        },
        recipientDocumentTypesDisplay(value) {
            if (value && this.recipientDocumentTypes.length === 0) this.loadRecipientDocumentTypes();
        },
        paymentLocationSelectDisplay(value) {
            if (value) this.loadLocations();
        },
        bankListSelectDisplay(value) {
            if (value) this.loadBanks();
        },
        recipientCountryId(value) {
            if (value > 0) {
                this.payment.exchangeRate = 0;
                this.payment.currencyId = 0;
                this.payment.paymentMethodId = 0;
                this.loadServices();
            }
        },
        totalCollected(value) {
            this.$store.state.simpleOrder.paymentInformation.totalCollected = parseFloat(value.replaceAll(',', ''));
        },
        debitCardSurcharge(value) {
            this.$store.state.simpleOrder.paymentInformation.debitCardSurcharge = parseFloat(value.replaceAll(',', ''));
        },
    },
};
</script>

<style lang="scss">
span.small-new-bank-account {
    min-width: 95px;
}

span.small-recipient-docs {
    min-width: 145px;
}

.small-select {
    div:first-child {
        max-height: 1.6rem !important;
    }

    input {
        font-size: 0.6rem !important;
    }

    .vs__dropdown-menu {
        top: calc(101% - var(--vs-border-width));
    }

    .vs__selected {
        margin: 0px 2px 0 !important;
        font-size: 0.6rem !important;
    }

    .vs__search {
        margin-top: 0 !important;
        padding: 0 7px !important;
    }
}

.w-40 {
    width: 40% !important;
}

@import '../../../assets/scss/simpleOrder.scss';
</style>
