<template>
    <div class="modal" id="modal-iban-accounts" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-md modal-fullscreen-lg-down">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">IBAN Accounts</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div class="table-responsive" v-show="!loading">
                        <table class="table table-borderless">
                            <tbody>
                                <tr v-for="item in data" :key="item" @click="select(item)" id="bankItem">
                                    <td>
                                        {{ item }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
    name: 'IbanAccounts',
    props: {
        recipientId: {
            type: Number,
        },
        payMethodId: {
            type: Number,
        },
    },
    data() {
        return {
            modal: null,
            data: null,
            loading: false,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-iban-accounts'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`niloagent/createorder/find/recipient/${this.recipientId}/ibanaccounts/${this.payMethodId}`)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        select(item) {
            this.$emit('selected', item);
            this.modal.hide();
        },
        show() {
            this.data = null;
            this.modal.show();
            this.load();
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

tbody {
    tr:hover {
        background-color: lighten($primary, 40);
        cursor: pointer;
    }
}
</style>
